
export default {
    install(vue) {
        const arr = require.context("@/components/", true, /.vue$/).keys();

        for (const p of arr) {
            const name = p.replace(/\.\/([\S]+?)\.vue/g, '$1');
            var c = require(`@/components/${name}`);
            console.log(name.replace('.vue', ''));
            vue.component(name.replace('.vue', ''), c.default || c);
        }
    }
}