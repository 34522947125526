<template>
  <div id="tabs">
    <div id="tabHead">
      <div
        class="item"
        v-for="(item, idx) in tabs"
        :class="{ active: selectIdx == idx }"
        @click="selectTab(idx)"
        :key="idx"
      >
        <div class="itemName" v-html="item.name"></div>
        <Icon @click.stop="remove(idx)" class="close" type="md-close" />
      </div>
    </div>
    <keep-alive>
      <div
        id="content"
        v-for="item in tabs"
        :key="item.name"
        v-show="selectItem != null && selectItem.name == item.name"
      >
        <component
          v-if="item != null"
          :is="item.component"
          :params="item.params"
        ></component>
      </div>
    </keep-alive>
  </div>
</template>
<script>
export default {
  props: {},
  name: "TabControl",
  data() {
    return {
      tabs: [],
      selectIdx: 0,
      selectItem: null,
    };
  },
  methods: {
    add(item) {
      for (let i = 0; i < this.tabs.length; i++) {
        const tab = this.tabs[i];
        if (tab.name == item.name) {
          this.selectTab(i);
          return;
        }
      }
      this.tabs.push(item);
      this.selectTab(this.tabs.length - 1);
    },
    selectTab(idx) {
      this.selectIdx = idx;
      this.selectItem = this.tabs[idx];
    },
    remove(idx) {
      this.tabs.splice(idx, 1);
      if (idx == this.selectIdx) {
        this.selectItem = null;
        if (this.tabs[idx] != null) {
          this.selectTab(idx);
          return;
        } else {
          for (let i = idx; i > -1; i--) {
            if (this.tabs[i] != null) {
              this.selectTab(i);
              return;
            }
          }
        }
      }
      if (this.tabs.length == 0) {
        this.$emit("clear");
      }
    },
  },
};
</script>
<style scoped>
#tabs {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

#tabHead {
  border-bottom: 1px solid #dcdee2;
  display: flex;
  margin-bottom: 10px;
  flex-shrink: 0;
  min-height: 2rem;
}

.item {
  padding: 3px 5px 3px 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  cursor: pointer;
}

.active {
  border-bottom: 1px solid #2d8cf0;
  color: #2d8cf0;
}

.item:hover {
  border-bottom: 1px solid #2d8cf0;
}

.itemName {
  padding-left: 10px;
  padding-right: 10px;
}

.close:hover {
  color: red;
}

#content {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  overflow: auto;
}
</style>
