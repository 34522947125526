<template>
  <div id="LoginPage">
    <div id="loginWindow">
      <h1 id="title">登录系统</h1>
      <div class="inputPanel">
        <Input v-model="uid" prefix="md-person" placeholder="用户名" />
      </div>
      <div class="inputPanel">
        <Input v-model="pwd" type="password" prefix="md-key" placeholder="输入密码" />
      </div>
      <div class="inputPanel">
        <Button type="primary" @click="login">登 录 系 统</Button>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
export default {
  name: "loginPage",
  data() {
    return {
      uid: "",
      pwd: "",
    };
  },
  methods: {
    async login() {
      const res = await this.$ws.send("admin.auth.login", {
        uid: this.uid,
        pwd: md5(this.pwd),
      });
      console.log(res);
      if (res.status != 200) {
        this.$Message.error(`${res.data}`);
        return;
      }
      localStorage.setItem("guid", res.guid);
      localStorage.setItem("uid", res.uid);
      this.$router.push({
        path: "/index",
      });
    },
  },
};
</script>
<style scoped src="@/assets/css/login.css"></style>
