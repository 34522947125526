import jQuery from "jquery";
// import { ElLoading as elLoading } from 'element-plus'
import iview from "view-ui-plus";

const testLoading = iview.Message.loading({
  content: "loading...",
  duration: 0,
});
testLoading();

export default function (httpBase) {
  let requestCount = 0;
  let completedCount = 0;
  const http = {
    // eslint-disable-next-line no-unused-vars
    async post(
      api,
      form,
      retType = "json",
      options = {
        text: "loading...",
      }
    ) {
      if (form["_guid_"] == null) {
        form["_guid_"] = localStorage.getItem("guid");
      }
      if (form["_uid_"] == null) {
        form["_uid_"] = localStorage.getItem("uid");
      }

      if (api.indexOf("http://") == -1 && api.indexOf("https://") == -1) {
        api = `${httpBase}${api}`;
      }
      requestCount++;
      // const loadingInstance = elLoading.service(options);
      const loading = iview.Message.loading({
        content: options.text,
        duration: 0,
        background: true,
      });
      let res = await jQuery.post(api, form);
      completedCount++;
      if (requestCount == completedCount) {
        // loadingInstance.close();
        loading();
      }
      if (retType == "json" && typeof res == "string") {
        res = JSON.parse(res);
      }
      return res;
    },
    // eslint-disable-next-line no-unused-vars
    async get(
      api,
      form,
      retType = "json",
      options = {
        text: "loading...",
      }
    ) {
      if (api.indexOf("http://") == -1 && api.indexOf("https://") == -1) {
        api = `${httpBase}${api}`;
      }
      if (form["_guid_"] == null) {
        form["_guid_"] = localStorage.getItem("guid");
      }
      if (form["_uid_"] == null) {
        form["_uid_"] = localStorage.getItem("uid");
      }

      requestCount++;
      // const close = elLoading.service(options);
      const loading = iview.Message.loading({
        content: options.text,
        duration: 0,
        background: true,
      });
      let res = await jQuery.get(api, form);
      completedCount++;
      if (requestCount == completedCount) {
        // close();
        loading();
      }
      if (retType == "json" && typeof res == "string") {
        res = JSON.parse(res);
      }
      return res;
    },

    async postFile(api,
      formData,
      retType = "json",
      options = {
        text: "loading...",
      }) {

      if (api.indexOf("http://") == -1 && api.indexOf("https://") == -1) {
        api = `${httpBase}${api}`;
      }

      formData.append(`_guid_`, localStorage.getItem("guid"));
      formData.append(`_uid_`, localStorage.getItem("uid"));

      requestCount++;
      // const loadingInstance = elLoading.service(options);
      const loading = iview.Message.loading({
        content: options.text,
        duration: 0,
        background: true,
      });
      let res = await jQuery.ajax({
        url: api, // 替换为您的上传URL
        type: 'POST',
        data: formData,
        processData: false, // 不要处理数据
        contentType: false, // 不要设置内容类型
      });
      completedCount++;
      if (requestCount == completedCount) {
        // loadingInstance.close();
        loading();
      }
      if (retType == "json" && typeof res == "string") {
        res = JSON.parse(res);
      }
      return res;
    }

  };
  return http;
}
