
export default {
    // localHttpApiBase: "http://localhost:12008",
    // localHttpApiBase: "wails://wails.localhost:12008",
    localHttpApiBase: "http://127.0.0.1:12008",
    localWsApiBase: "ws://localhost:12008",

    serverWsApiBase: "ws://api.dgsc.nobugltd.com",
    serverHttpApiBase: `http://api.dgsc.nobugltd.com`,

    // serverWsApiBase: "ws://localhost:5001",
    // serverHttpApiBase: `http://localhost:5001`,

}