import { createRouter, createWebHashHistory } from "vue-router";

const vueRouterOptions = {
    history: createWebHashHistory(),
};

//自动创建路由信息
Object.defineProperty(vueRouterOptions, "routes", {
    get() {
        const routers = [
            {
                path: "/",
                // redirect: "/index",
                component: require(`@/pages/login.vue`).default
            },
        ];
        //读取pages文件夹
        const arr = require.context("@/pages/", true, /.vue$/).keys();
        for (const p of arr) {
            let name = p.replace(/^\.\/([\S]+?)\.vue/g, "$1");
            const page = require(`@/pages/${name}`).default;
            routers.push({
                path: `/${name}`,
                component: page,
            });
            // console.log(name, page);
        }
        return routers;
    },
});

const router = createRouter(vueRouterOptions);

export default router;
