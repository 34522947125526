<template>
    <div id="pageHead">
        <div id="title" v-html="title"></div>
        <div>
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: ['title'],
    name: "pageHead",
    data() {
        return {};
    }
}
</script>

<style scoped>
#pageHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #f3f3f3;
    border-left: 5px solid #2d8cf0;
    margin-bottom: 10px;
}

#title {
    font-weight: bold;
}
</style>