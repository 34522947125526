<template>
  <div id="wallpaperTypeManage">
    <pageHead title="分类管理">
      <div>
        <Button type="primary" size="small" @click="addTypeBtnClick">添加分类</Button>
      </div>
    </pageHead>
    <div class="row full" id="dataTablePanel">
      <Table :columns="columns" :data="list"></Table>
    </div>
    <Drawer v-model="showAdd" :title="form.ID == null ? '添加分类' : '修改分类'" :width="500">
      <Form label-position="right" :label-width="50">
        <FormItem label="封面">
          <Button v-if="form.cover == ''" type="primary" size="small" @click="coverClick">选择封面</Button>
          <img v-else id="cover" @click="coverClick" :src="form.cover" alt="" />
          <input id="file" ref="file" type="file" accept="image/gif,image/jpeg,image/jpg,image/png"
            @change="selectFileEvent" />
        </FormItem>
        <FormItem label="名称">
          <Input type="text" placeholder="输入类型名称" v-model="form.typeName"></Input>
        </FormItem>
        <FormItem label="排序">
          <Input type="text" placeholder="输入排序编号" v-model="form.sortID"></Input>
        </FormItem>
        <FormItem label="可见">
          <RadioGroup v-model="form.enabled">
            <Radio label="1">显示</Radio>
            <Radio label="0">不显示</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="submit">提交</Button>
        </FormItem>
      </Form>
    </Drawer>
  </div>
</template>
<script>
import { resolveComponent } from "vue";
export default {
  data() {
    const self = this;
    return {
      list: [],
      showAdd: false,
      form: {
        ID: null,
        //类型名称
        typeName: '',
        //封面
        cover: '',
        sortID: 0,
        enabled: "1",
      },
      columns: [
        {
          key: "ID",
          title: "ID",
          width: "100",
          align: "center",
        },
        {
          key: "cover",
          title: "封面",
          width: 200,
          align: "center",
          render(h, { row }) {
            let url = row.cover.indexOf('base64') > -1 ? row.cover : `${location.protocol}//dgsc.nobugltd.com/wallpaper/${row.cover}`;
            return h('img', {
              class: "cover",
              src: url
            });
          }
        },
        {
          key: "typeName",
          title: "名称",
          align: "center",
          width: 300
        },
        {
          key: "sortID",
          title: "排序",
          align: "center",
          width: 100
        },
        {
          key: "enabled",
          title: "是否可见",
          width: 100,
          align: "center",
          render(h, { row }) {
            if (row.enabled == '1') {
              return h('span', {
                style: "color:green"
              }, '可见');
            } else {
              return h('span', {
                style: "color:red"
              }, '不可见');
            }
          }
        }, {
          title: "操作",
          width: "200",
          render(h, { row }) {
            return h('div', [
              h(resolveComponent('Button'), {
                type: "error",
                size: "small",
                onClick: () => {
                  row.enabled = '0';
                  self.form = row;
                  self.submit();
                }
              }, "删除"),
              h(resolveComponent('Button'), {

                type: "primary",
                size: "small",

                style: "margin-left:5px",
                onClick() {
                  self.form = row;
                  self.showAdd = true;
                },
              }, "修改")

            ]);
          }
        }
      ],
    };
  },
  methods: {
    //提交
    async submit() {
      let api = `/admin/wallpaperType/add`;
      if (this.form.ID != null) {
        api = `/admin/wallpaperType/renew`;
      }
      const res = await this.$http.post(api, {
        type: JSON.stringify(this.form)
      });
      if (res.status != 200) {
        this.$Message.error(res.message);
        return;
      }
      this.$Message.success("操作成功");
      this.getList();
      this.showAdd = false;
    },
    //选择文件后的事件
    selectFileEvent() {
      const list = this.$refs["file"].files;
      if (list.length == 0) {
        return;
      }
      const file = list[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const _ = this;
      reader.onload = function () {
        _.form.cover = this.result;
      };
    },
    //显示添加类型
    addTypeBtnClick() {
      this.form = {
        ID: null,
        //类型名称
        typeName: '',
        //封面
        cover: '',
        sortID: 0,
        enabled: "1",
      };
      this.form.sortID = this.list.length;
      this.showAdd = true;
    },
    //获取列表
    async getList() {
      const res = await this.$http.post("/admin/wallpaperType/getAll", {});
      this.list = res.data;
      console.log(res);
    },
    //选择封面
    coverClick() {
      this.$refs["file"].click();
    },
  },
  async mounted() {
    //获取分类列表
    {
      await this.getList();
    }
  },
};
</script>
<style scoped src="@/assets/css/wallpaperTypeManage.css"></style>
<style>
#wallpaperTypeManage .cover {
  width: 160px;
  height: 90px;
  object-fit: cover;
}
</style>