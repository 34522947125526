<template>
  <Submenu v-if="data.children != null && data.children.length > 0" :name="`funcm_${data.ID}`">
    <template #title>
      <div class="menuPanel">
        <div class="menuIconPanel" v-if="data.icon != ''">
          <img :src="data.icon" alt="" />
        </div>
        <div class="menuIconPanel" v-else>
          <Icon type="ios-folder" />
        </div>
        <div class="name" v-html="data.name"></div>
      </div>
    </template>
    <indexMenu v-for="(menu, idx) in data.children" @select="select" :key="idx" :data="menu"></indexMenu>
  </Submenu>
  <MenuItem v-else :name="`funcm_${data.ID}`" @click.stop="select(data)">
  <div class="menuPanel">
    <div class="menuIconPanel" v-if="data.icon != ''">
      <img :src="data.icon" alt="" />
    </div>
    <div class="menuIconPanel" :class="{ textMenuIconPanel: data.type == 'text' }" v-else>
      <Icon type="ios-paper-outline" v-if="data.type == 'text'" />
      <Icon type="ios-folder" v-else />
    </div>
    <div class="name" v-html="data.name"></div>
  </div>
  </MenuItem>
</template>
<script>
import indexMenu from "@/components/indexMenu";
export default {
  props: ["data"],
  components: {
    indexMenu,
  },
  data() {
    return {};
  },
  methods: {
    select(obj) {
      this.$emit("select", obj);
    },
  },
};
</script>

<style scoped>
.menuPanel {
  display: flex;
  align-items: center;
}

.menuIconPanel {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  cursor: pointer;
  object-fit: cover;
  background: #ba261a;
  border-radius: 0.75rem;
  text-align: center;
  color: white;
  font-weight: bold;
}
.textMenuIconPanel{
  background: #f55244;
}

.menuIconPanel img {
  width: 1rem;
  height: 1rem;
  margin: 0.25rem;
}

.name {
  margin-left: 5px;
}
</style>
