<template>
    <div id="broadcastPage">
        <pageHead title="全体广播消息">
            <Select v-model="type.name" @on-change="changeType" size="small" style="width:200px" placeholder="请选择消息类型">
                <Option v-for="item in typeList" :value="item.name" :key="item.name">
                    {{ item.name }}
                </Option>
            </Select>
        </pageHead>
        <Form :label-width="120" style="width:500px;">
            <FormItem v-for="p in type.params" :label="p.name" :key="p.key">
                <Input v-model="p.value" type="textarea"></Input>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="send" v-if="type.name != ''">发送广播消息</Button>
            </FormItem>
        </Form>

    </div>
</template>
<script>
export default {
    data() {
        return {
            type: {
                name: "",
                value: "",
                params: []
            },
            typeList: [
                //弹窗消息
                {
                    name: "弹窗消息",
                    value: "dialog",
                    params: [
                        {
                            key: "title",
                            name: '弹窗标题',
                            value: '',
                        },
                        {
                            key: "width",
                            name: '弹窗宽度',
                            value: '',
                        },
                        {
                            key: "content",
                            name: '弹窗内容',
                            value: '',
                        },
                        {
                            key: "btnText",
                            name: '按钮文字',
                            value: '',
                        },
                    ]
                },
                //弹窗消息
                {
                    name: "刷新app界面",
                    value: "refshApp",
                    params: []
                }
            ]
        };
    },
    methods: {
        async send() {
            localStorage.setItem("lastBroadcastMessage", JSON.stringify(this.type));
            // this.$ws.send("broadcast.subscribe", {});
            const data = {};
            for (const p of this.type.params) {
                data[p.key] = p.value;
            }
            const res = await this.$ws.send("admin.broadcast.broadcastMessage", { type: this.type.value, data: data });
            this.$Message.success(res.data);
        },
        changeType(v) {
            for (const type of this.typeList) {
                if (type.name == v) {
                    this.type = type;
                    console.log(this.type);
                    break;
                }
            }
        },
    },
    mounted() {
        let str = localStorage.getItem("lastBroadcastMessage");
        if (str != '' && str != null) {
            this.type = JSON.parse(str);
        }
    },
}
</script>