import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "view-ui-plus/dist/styles/viewuiplus.css";
import ViewUIPlus from "view-ui-plus";
/**
 * 自定义组件
 */
import loadComponents from "./loadComponents";

/**
 * 自定义插件
 */
import customPlugs from './customPlugs';

const app = createApp(App);

app.use(ViewUIPlus)
    .use(router)
    .use(loadComponents)
    .use(customPlugs)
    .mount('#app')
