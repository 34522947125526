<template>
  <div id="wallpaperTypeManage" class="col full w100 h100">
    <pageHead title="壁纸管理">
      <div>
        <Select v-model="selectType" style="width: 200px" @on-change="getList" size="small">
          <Option :value="-1">全部</Option>
          <Option v-for="item in typeList" :value="item.ID" :key="item.ID">{{
          item.typeName
        }}</Option>
        </Select>
        <Button type="primary" size="small" style="margin-left: 5px" @click="addWallpaperBtnClick">添加壁纸</Button>
      </div>
    </pageHead>
    <div class="full" id="wallpaperListPanel">
      <div class="wallpaperItem" v-for="item in viewList" :key="item.ID">
        <div class="wallpaperOperatePanel">
          <div class="operateIco row vCenter hCenter" @click="copy(imgServer + item.source)">
            <Icon type="md-link" />
          </div>
          <div class="operateIco row vCenter hCenter" @click.stop="editWallpaper(item)">
            <Icon type="md-create" />
          </div>
          <div class="operateIco row vCenter hCenter" @click.stop="deleteWallpaper(item)">
            <Icon type="md-close" />
          </div>
        </div>
        <!-- <Image :src="imgServer + item.cover" fit="cover" width="100%" height="100%" preview /> -->
        <img :src="imgServer + item.cover" @click.stop="viewImage(imgServer + item.source)" alt="" />
        <div class="noDisplay" v-if="item.enabled == '0'">不显示</div>
      </div>
    </div>
    <div class="row w100 vCenter hCenter" style="padding: 10px">
      <Page :total="list.length" v-model="currentPage" :page-size="pageSize" />
    </div>
    <!-- 图片浏览 -->
    <ImagePreview v-model="preview" :preview-list="previewList" />
    <!-- 抽屉 -->
    <Drawer v-model="showAdd" :title="form.ID == null ? '添加壁纸' : '修改壁纸'" :mask-closable="false" :width="1000">
      <Form label-position="right" :label-width="50">
        <FormItem label="名称" v-show="form.type != 'package'">
          <Input type="text" style="width: 280px" placeholder="壁纸名称，多张自动加1-N" v-model="form.wallpaperName"></Input>
        </FormItem>
        <FormItem label="类型">
          <Select v-model="form.typeID" style="width: 280px">
            <Option v-for="item in typeList" :value="item.ID" :key="item.ID">{{
          item.typeName
        }}</Option>
          </Select>
        </FormItem>
        <FormItem label="类型">
          <RadioGroup v-model="form.type">
            <Radio label="img">图片</Radio>
            <Radio label="video">视频</Radio>
            <Radio label="url">网页</Radio>
            <Radio label="package" v-if="form.ID == null">壁纸包</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="密码" v-if="form.type == 'package'">
          <Input type="text" style="width: 280px" placeholder="输入壁纸包密码" v-model="form.password"></Input>
        </FormItem>
        <FormItem label="文件" v-if="form.type == 'package'">
          <input type="file" ref="packageFile" placeholder="选择壁纸包文件" accept=".wallpaper" />
        </FormItem>
        <FormItem label="封面" v-if="form.type == 'url' || form.type == 'video'">
          <input type="file" ref="coverFile" placeholder="选择封面文件" accept="image/gif,image/jpeg,image/jpg,image/png" />
        </FormItem>
        <FormItem label="视频" v-if="form.type == 'video'">
          <input type="file" ref="videoFile" placeholder="选择壁纸文件" accept="video/mp4" />
        </FormItem>
        <FormItem label="链接" v-if="form.type == 'url'">
          <Input type="text" style="width: 280px" placeholder="输入网页链接" v-model="form.url"></Input>
        </FormItem>
        <FormItem label="可见">
          <RadioGroup v-model="form.enabled">
            <Radio label="1">显示</Radio>
            <Radio label="0">不显示</Radio>
          </RadioGroup>
        </FormItem>
      </Form>
      <Form label-position="right" :label-width="50" v-if="form.type == 'img'">
        <FormItem label="壁纸">
          <Button v-if="form.ID == null" type="primary" size="small" @click="wallpaperClick">
            添加壁纸
          </Button>
          <div id="wallpaperList" v-if="form.ID == null">
            <div v-for="(item, idx) in urlList" :key="idx" class="formWallpaperImg">
              <Icon class="removeIcon" @click="urlList.splice(idx, 1)" type="md-close-circle" />
              <img :src="item.url" alt="" />
            </div>
          </div>

          <img style="width: 200px; height: 112.5px; object-fit: cover" v-if="form.ID != null" @click="wallpaperClick"
            :src="form.source.indexOf('blob:') > -1 ? form.source : imgServer + form.source
          " alt="" />
          <input id="file" ref="file" type="file" :multiple="form.ID == null"
            accept="image/gif,image/jpeg,image/jpg,image/png" @change="selectFileEvent" />
        </FormItem>
      </Form>
      <Form :label-width="50">
        <FormItem>
          <Button type="primary" @click="submit"> 提交 </Button>
        </FormItem>
      </Form>
    </Drawer>
  </div>
</template>
<script>
let fileID = 0;
export default {
  data() {
    return {
      preview: false,
      previewList: [],
      imgServer: `${location.protocol}//dgsc.nobugltd.com/wallpaper/`,
      typeList: [],
      list: [],
      showAdd: false,
      selectType: -1,
      currentPage: 1,
      pageSize: 35,
      form: {
        ID: null,
        wallpaperName: "",
        typeID: "",
        source: "",
        enabled: "1",
        type: "img",
        url: "",
        password: "",
      },
      //选择的图片列表
      urlList: [],
    };
  },
  methods: {
    //提交
    async submit() {
      console.log(this.form);
      const formData = new FormData();

      if (this.form.enabled == "1") {
        if (this.form.wallpaperName == "" && this.form.type != 'package') {
          this.$Message.error("名称不能为空");
          return;
        }
        if (this.form.typeID == "" || this.form.typeID == -1) {
          this.$Message.error("请选择壁纸类型");
          return;
        }

        if (this.form.type == 'img') {
          if (this.urlList.length == 0 && this.form.ID == null) {
            this.$Message.error("请添加壁纸");
            return;
          }
        }
      }

      if (this.form.type == 'video') {
        formData.append("cover", this.$refs.coverFile.files[0]);
        formData.append("video", this.$refs.videoFile.files[0]);
      } else if (this.form.type == 'url') {
        formData.append("cover", this.$refs.coverFile.files[0]);
      } else if (this.form.type == 'package') {
        formData.append("package", this.$refs.packageFile.files[0]);
        formData.append("password", this.form.password);
      }


      let api = "/admin/wallpapers/add";
      if (this.form.ID != null) {
        api = "/admin/wallpapers/renew";
      }
      let idx = 0;
      for (const item of this.urlList) {
        idx++;
        formData.append(`file${idx}`, item.file);
      }
      formData.append("wallpaper", JSON.stringify(this.form));


      const res = await this.$http.postFile(api, formData);
      if (res.status != 200) {
        this.$Message.error(res.message);
        return;
      }
      this.$Message.success("操作成功");
      this.showAdd = false;
      this.getList();
    },
    //编辑壁纸
    editWallpaper(wallpaper) {
      //先变空再赋值，把表单里的input type=file删掉，不然会有留存的值
      this.form.type = '';
      this.form = wallpaper;
      this.showAdd = true;
    },
    //删除壁纸
    deleteWallpaper(wallpaper) {
      console.log("deleteWallpaper", wallpaper);
      wallpaper.enabled = "2";
      this.form = wallpaper;
      this.submit();
    },
    //选择文件后的事件
    selectFileEvent() {
      const list = this.$refs["file"].files;
      if (list.length == 0) {
        return;
      }
      for (const file of list) {
        console.log(file);

        this.urlList.push({
          file: file,
          id: ++fileID,
          url: URL.createObjectURL(file),
        });

        if (this.form.ID != null) {
          this.form.source = this.urlList[0].url;
        }

        // const reader = new FileReader();
        // reader.readAsDataURL(file);
        // const _ = this;
        // reader.onload = function () {
        //   _.urlList.push(this.result);
        //   //修改
        //   if (_.form.ID != null) {
        //     console.log(_.urlList);
        //     _.form.source = _.urlList[0];
        //   }
        // };
      }
    },
    //显示添加类型
    addWallpaperBtnClick() {
      //先设置type为空让表单上的input被删掉
      this.form.type = '';
      this.urlList = [];
      this.form = {
        ID: null,
        wallpaperName: "",
        typeID: "",
        source: "",
        enabled: "1",
        type: "img",
        url: "",
        password: ""
      };
      if (this.selectType != "-1") {
        this.form.typeID = this.selectType;
      }
      this.showAdd = true;
    },

    //获取类型列表
    async getTypeList() {
      const res = await this.$http.post("/admin/wallpaperType/getAll", {});
      this.typeList = res.data;
      console.log(res);
    },
    //获取壁纸列表
    async getList() {
      const res = await this.$http.post("/admin/wallpapers/getAllByType", {
        typeID: this.selectType,
      });
      this.list = res.data;
      console.log(res);
    },
    //图片浏览
    viewImage(url) {
      this.previewList = [url];
      this.preview = true;
    },
    //复制
    async copy(text, msg = "复制成功") {
      await navigator.clipboard.writeText(text);
      this.$Message.success(msg);
    },
    //图片壁纸选择文件
    wallpaperClick() {
      this.$refs["file"].click();
    },
  },
  async mounted() {
    //获取分类列表
    {
      await this.getTypeList();
    }
    //获取壁纸列表
    {
      await this.getList();
    }
  },
  computed: {
    viewList() {
      return this.list.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    },
  },
};
</script>
<style scoped src="@/assets/css/wallpaperManage.css"></style>
