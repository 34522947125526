<template>
  <router-view> </router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style src="@/assets/css/base.css"></style>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: fixed;
  overflow: hidden;
}
</style>
