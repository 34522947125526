<template>
  <div id="indexPage">
    <div id="menuPanel">
      <div id="logo">
        <img src="/favicon.ico" alt="" />
        <div>后台管理</div>
      </div>
      <Menu theme="dark" width="280px">
        <Submenu name="1">
          <template #title>
            <Icon type="ios-image-outline" />
            内容管理
          </template>
          <MenuItem @click="selectMenu('/wallpaperTypeManage')" name="/wallpaperTypeManage">
          分类管理
          </MenuItem>
          <MenuItem @click="selectMenu('/wallpaperManage')" name="/wallpaperManage">
          壁纸管理
          </MenuItem>
        </Submenu>
        <Submenu name="2">
          <template #title>
            <Icon type="ios-megaphone-outline" />
            消息广播
          </template>
          <MenuItem @click="selectMenu('/broadcastPage')" name="/broadcastPage">
          全体消息
          </MenuItem>
        </Submenu>
        <Submenu name="3">
          <template #title>
            <Icon type="ios-globe-outline" />
            平台数据
          </template>
          <MenuItem @click="selectMenu('/menuManager')" name="/menuManager">
          用户管理
          </MenuItem>
        </Submenu>
        <Submenu name="999">
          <template #title>
            <Icon type="ios-settings-outline" />
            系统管理
          </template>
        </Submenu>
      </Menu>
    </div>
    <div id="mainWindow">
      <div id="logout" @click="logout">
        <Icon type="md-exit" id="logoutIco" /><span>退出登录</span>
      </div>
      <myTabs @clear="tabClearEvent" ref="myTabs" :list="tabs"></myTabs>
    </div>
  </div>
</template>
<script>
import myTabs from "@/components/tabs";
export default {
  name: "indexPage",
  components: {
    myTabs,
  },
  data() {
    return {
      components: {},
      tree: [],
    };
  },
  methods: {
    selectMenu(obj) {
      console.log(obj);
      if (typeof obj == "string") {
        const dict = {
          "/broadcastPage": "全体消息",
          "/wallpaperTypeManage": "分类管理",
          "/wallpaperManage": "壁纸管理",
          "/partmembersManager": "党员管理",
        };
        this.addTab(dict[obj], obj);
        return;
      } else {
        if (obj.type == "menu") {
          console.log("#######");
          //ID=116通知、117公告
          if (obj.ID == 116 || obj.ID == 117) {
            this.addTab(obj.ID == 116 ? "通知管理" : "公告管理", "/contentManager", {
              menuID: obj.ID,
              type: obj.ID == 116 ? "notify" : "notice",
            });
          } else {
            this.addTab(obj.name + "-内容管理", "/contentManager", {
              menuID: obj.ID,
              type: "text",
            });
          }
        } else {
          this.addTab(obj.name + "-内容编辑", "/textEdit", { menu: obj });
        }
      }
    },

    addTab(name, path, params) {
      this.$refs.myTabs.add({
        name,
        component: this.getPage(path),
        params,
      });
    },
    //当tab清空时无任何标签
    tabClearEvent() {
      // this.addTab("欢迎页", "/welcomePage", {});
    },
    getPage(path) {
      if (this.components[path] == null) {
        this.components[path] = require(`@/pages${path}`).default;
      }
      return this.components[path];
    },

    logout() {
      localStorage.removeItem("guid");
      localStorage.removeItem("uid");
      this.$router.replace({
        path: "/login",
      });
    },
  },
  mounted() { },
};
</script>
<style scoped src="@/assets/css/index.css"></style>
